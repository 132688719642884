import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { initI18nLean } from '@wix/communities-blog-client-common';
import '../../../rich-content/components/rich-content-editor/rich-content-pg.global.scss';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import PermissionsProvider from '../../../common/components/permissions-provider';
import PopoverRoot from '../../../common/components/popovers/popover-root';
import {
  getLanguage,
  isEditor,
  getIsICUEnabled,
  getIsMobile,
  getIsRTL,
  isSeo as getIsSeo,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import AppLoaded from '../../../common/components/app-loaded';
import { isInWix } from '../../../common/services/is-in-wix';
import { getIsCreatedWithResponsiveEditor } from '../../../common/selectors/app-settings-selectors';
import App from '../../../common/components/app';
import ModalRoot from '../modals/modal-root';
import MessageRoot from '../messages/message-root';
import MyPosts from '../my-posts';
import MyPostsFrame from '../my-posts-frame';
import styles from './app-root.scss';

const MyPostsPage = () => (
  <div className={styles.baseStyles}>
    <MyPostsFrame>
      <MyPosts />
      <AppLoaded />
    </MyPostsFrame>
  </div>
);
class AppRoot extends React.Component {
  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
    if (this.props.isEditor && this.props.createdWithResponsiveEditor) {
      document.body.style.height = 'auto';
    }
  }

  getI18nConfig = () => {
    const { language, translations, isICUEnabled } = this.props;

    if (!this.i18nConfig) {
      this.i18nConfig = initI18nLean(language, translations, isICUEnabled);
    }

    return this.i18nConfig;
  };

  render() {
    const { isMobile, isRtl, isSeo } = this.props;
    const i18n = this.getI18nConfig();

    return (
      <ResponsiveListener>
        <PermissionsProvider>
          <I18nextProvider i18n={i18n}>
            <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRtl, seo: isSeo }}>
              <App
                main={<MyPostsPage />}
                modalRoot={<ModalRoot />}
                messageRoot={<MessageRoot />}
                popoverRoot={<PopoverRoot />}
              />
            </TPAComponentsProvider>
          </I18nextProvider>
        </PermissionsProvider>
      </ResponsiveListener>
    );
  }
}

AppRoot.propTypes = {
  isICUEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  isEditor: isEditor(state),
  isMobile: getIsMobile(state),
  isRtl: getIsRTL(state),
  isSeo: getIsSeo(state),
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  isICUEnabled: getIsICUEnabled(state),
});

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
