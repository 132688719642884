import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { TextButton, TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { connect } from '../../../../common/components/runtime-context';
import { getCreatePostUrl } from '../../../../common/services/get-post-url';
import { getTopology } from '../../../../common/store/topology/topology-selectors';
import PostLink from '../../../../common/components/link/post-link';
import withTranslate from '../../../../common/hoc/with-translate';
import { classes } from './create-post-button-desktop.st.css';

const CreatePostButtonDesktop = ({ t, createPostUrl }) => (
  <TextButton as={PostLink} href={createPostUrl} className={classes.root} priority={TEXT_BUTTON_PRIORITY.primary}>
    {t('create-post-button.label')}
  </TextButton>
);

CreatePostButtonDesktop.propTypes = {
  t: PropTypes.func.isRequired,
  createPostUrl: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state) => ({
  createPostUrl: getCreatePostUrl(getTopology(state)),
});

export default flowRight(withTranslate, connect(mapRuntimeToProps))(CreatePostButtonDesktop);
